/* global AWS */
// import AWS from 'aws-sdk'
// import * as AWS from '../aws.core.min.js'
export const awsRegion = 'us-west-2'
export const cognitoRegion = 'us-west-2'
export const cognitoUserPoolId = 'us-west-2_oswoBBaxj'
export const cognitoIdentityPoolId = 'us-west-2:1b535d52-e1e1-4c88-93c1-68b6912fcb2b'
export const cognitoClientId = 'rsbi2pofrkvv5cpj295ha7ljr'
export const cognitoUserPoolDomain = 'tr-portal-prod.auth.us-west-2.amazoncognito.com'
export const websiteUrl = 'https://threat-removal.deep-secure.com/'
export const apiUrl = 'https://threat-removal.deep-secure.com/prod'
export const apiGatewayApiId = 'x4vu8p45hg' // the minimum needed for running locally in a meaningful way
export const s3BucketName = 'tr-portal-prod'
export const accountId = '489146924268'
export const mfaProviderName = 'API-developer-portal'

export function getApiGatewayPublicApiUrl() {
    return apiUrl
}

export function getApiGatewayRealApiHost() {
    return `${apiGatewayApiId}.execute-api.${awsRegion}.amazonaws.com`
}

AWS.config.region = cognitoRegion
